<template>
  <div class="container-fluid blog-container bg-blog">
    
    

    <section class="row" id="topo">
      <div class="col-md-9 col-lg-10 px-lg-5" style="background-color:rgba(68,13,69,0.95)">
<br>
         <h3 class="txt-titulo-branco bold" id="blog" >Blog</h3>
 <p class="txt-white paragrafo-blog">
Seja bem-vindo ao blog dos Tarimbados, onde exploramos com mais detalhes as técnicas, dicas, truques e sugestões que fazem a diferença na cozinha.
</p>
<router-link to="/blog" style="max-width:300px !important"   class="btn btn-light btn-block btn-search-ingredients bold">Voltar</router-link>


<br>
<img :src="resultado.AV_CAPA" class="img-fluid" alt="capa postagem tarimba"/>
<hr style="border-color:#f18800;">
<p class="txt-white paragrafo-blog">{{resultado.AV_DATA}}</p>
<h2 class="txt-white">{{resultado.AV_NOME}}</h2>
<div id="conteudo-blog" class="txt-white" v-html="resultado.AV_TEXTO"></div>




<div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <br><br>



        <h2 class="bold txt-white" style="">Deixe seu comentário</h2>
        <textarea v-model="comentario" class="form-control" maxlength="500" spellcheck="true" placeholder="Deixe seu comentário aqui..."></textarea><br>
  <button @click="comentar" class="btn btn-light bold float-right" style="color:#FFF; background-color: #400041 !important;">ENVIAR COMENTÁRIO</button>
  <div class="clearfix"></div>
<hr>
<h2 class="bold txt-white" style="">Comentários</h2>
<br>
<div style="background:#FFF;" class="px-2 py-2 my-2">
<Comentario v-if="id" :blog="true" :url="'gerenciaComentarios/getComentariosBlog/'+ this.id" :id="parseInt(this.id)" :key="'comment'+id"/>
  </div>      
        </div><!-- blog -->

        <div class="col-md-3 col-lg-2" style="background-color:rgba(241,136,0,0.95)">
          <br>

 <Anuncio :id="8" padrao="/img/blog_anuncio.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="8" padrao="/img/blog_anuncio.jpg" class="d-sm-none" :mobile="true" />

  <br>
<h3 class="txt-titulo-branco bold" >Busca por palavra</h3>
<div class="input-group mb-3" >
                 

                    <input type="text" v-model="palavra" class="form-control input-busca">
                    <div class="input-group-append" style="cursor:pointer;">
                      <span @click="buscarPalavra"  class="input-group-text"> <img  src="/img/magnifying-glass-4x.png"  alt="Lupa" /></span>
                    </div>
                  </div>

<br>

<h3 class="txt-titulo-branco bold" >Marcadores</h3>
          <ul>
            <li  v-scroll-to="'#topo'" @click="filtrar(item.CB_NOME)" style="cursor:pointer;color:#FFF;" v-for="(item,index) in marcadores" :key="'marcador'+index">{{item.CB_NOME}}</li>
          </ul>

<br>


<h3 class="txt-titulo-branco bold" >Mais Recentes</h3><hr>

<div v-for="(item,index) in recentes.itens" :key="'thumb'+index">
  <img :src="recentes.url + item.AV_THUMBNAIL" class="img-fluid" alt="thumbnail postagem tarimba"/>
  <p class="bold txt-white">{{item.AV_NOME}}</p>
  <p class="bold txt-white">{{item.AV_DATA}}</p>
  <router-link :to="'/postagem/'+item.AV_SLOGAN" class="btn-ler-thumb  px-2" v-scroll-to="'#blog'">Continuar Lendo...</router-link>
  <hr>
</div>

        
        </div><!-- pesquisa -->
    </section>





<!-- -->


 <div class="modal" id="modal-blog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>



  </div>
</template>

<style>
.bg-blog{background:url('/img/background.png');background-size: cover;background-repeat: no-repeat;}
.txt-titulo-branco{color:#FFF;font-weight:bold;}
.txt-white{color:#FFF;}
.paragrafo-blog{font-size:1.5em;}
.btn-ler-thumb {
    background-color: #FFF;
    border: none;
    color: #000;
    max-width:300px !important;
}

@media all and (max-width:768px){
  #conteudo-blog img{max-width:350px !important;height:auto;}
}
</style>

<script>
import servidor from '@/servidor'
import Comentario from '../components/Comentario.vue'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'Publicacao',

  components: {Comentario,Anuncio},
  metaInfo () {
    return {
      title: this.title,

      meta: [
        { name: 'description', content: this.description }
      ]
    }
  },
  data(){
    return {
      title:"Blog - Tarimba na cozinha",
      description:"Blog",
      semResultados:false,
      showLoading:false,
      texto:'',
      resultado:{},
      recentes:{},
      comentario:"",
      marcadores:[],
      id:null,
      palavra:'',
      marcador:''
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }

  },
  methods:{
    filtrar(item){
       this.marcador = item;
       this.$store.commit('mudarMarcador', this.marcador);
       this.$router.push('/blog');
    },
     buscarPalavra(){
       this.$store.commit('mudarPalavra',this.palavra);
       this.$router.push('/blog');
     },
      getMarcadores(){
      fetch(servidor+'gerenciaBlog/getCategorias/')
          .then((response) => response.json())
          .then((data) => {
             data.forEach(element => {
                this.marcadores.push(element);
            });
          });

    },
     abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-blog').modal('show');
   },
    voltar(){
    window.history.back();
  },
    getPostagem(){
    this.showLoading = true;
    let filtros = new FormData();
    filtros.append('slogan',this.$route.params.id);
    
    
      fetch(servidor+'gerenciaBlog/getPostagem/',{method:"POST", body: filtros})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            
                this.resultado = data;
                this.id = data.AV_ID;
                this.title = data.AV_NOME + " - Tarimba na cozinha";
                this.description = data.AV_RESUMO;
            
          });

    },
    getRecentes(){
    
      fetch(servidor+'gerenciaBlog/getRecentes/')
          .then((response) => response.json())
          .then((data) => {
                this.recentes = data;
          });

    },
     comentar(){
       if(this.$store.state.usuario == null){ this.abrirModal("É nescessário estar logado para fazer um comentantário.");return;}
      if(this.comentario != ''){
         let dados = new FormData();
         dados.append('usuario',this.getUsuario);
         dados.append('comentario',this.comentario);
         dados.append('tipo','Blog');
         dados.append('resposta','Não');
         dados.append('conta',this.$store.state.usuario.permissao);
         fetch(servidor+'gerenciaComentarios/comentar/'+ this.id,{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.comentario = "";
            console.log(data);
            this.abrirModal("Obrigado por enviar seu comentário! Ele será análisado pela nossa equipe e será publicado em breve.");
          });
      }
    }
    
   
  
  },
  watch: {
  $route(){
    this.getPostagem();
  }
  
  },
  mounted(){
    
    this.getPostagem();
    this.getRecentes();
    this.getMarcadores();
     this.$scrollTo("#topo");
  }
  
}
</script>
